import { Link, Outlet } from "react-router-dom"

const HeaderLinks = () => (
    <>
        <div className="header-links">
            <Link className="header-links-left" to="/">Carlos Hurtado</Link>
            <div className="header-links-right">
                <Link className="header-link-right" to="/">Home</Link>
                <p className="header-sep">&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <Link className="header-link-right" to="/projects">Projects</Link>
                <p className="header-sep">&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <Link className="header-link-right" to="/writing">Writing</Link>
            </div>
        </div>
        <Outlet />
    </>
);

export {HeaderLinks};